
.bulk-upload .next-btn {
  bottom: 10px;
  position: absolute;
  right: 10px;
}


.page-upload-content {
  background: #f0f2f5;
  min-height: calc(90vh - 90px);
  padding: 30px;
  padding-top: 12px;
  margin: auto;
  width: 100%;
  max-width: 1120px;
}

.bulk-upload {
  margin: 40px;
  min-height: 70vh;
  padding-top: 30px;
  margin: auto;
  width: 100%;
  max-width: 1120px;
  display: block;
  background-color: white;
}

.ant-upload-list {
  margin-bottom: 30px!important;
}

.ant-upload-list-item-card-actions-btn {
  opacity: 1!important;
  color: #ff4d4f!important;
}

.ant-upload-list-text .ant-upload-span {
  font-size: 18px;
  color: #294c7c!important;
  font-weight: bold;
}

.bulk-upload .ant-upload.ant-upload-drag {
  height: auto;
}

.bulk-upload .ant-upload {
  margin-top: 0px!important;
}

.bulk-upload .ant-upload-list-item-name {
  width: 100%;
}

.bulk-upload .ant-upload-list-item-name {
  overflow: inherit;
}

.bulk-upload .ant-steps-item-process .ant-steps-item-icon {
  border-color: none;
}