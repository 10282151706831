.cure-main-container .alert-nav {
  background: #294c7c !important;
  z-index: 1;
  width: 100%;
  padding: 0 10% !important;
}

.ant-collapse-expand-icon {
  margin-top: 6px!important;
}

.cure-main-container .menu-nav,
.cure-main-container .menu-nav-mobile {
  background: rgb(55, 105, 152);
  z-index: 1;
  width: 100%;
  padding: 0px 12px;
  font-family: GlacialIndifferenceBold;
  padding-left: 24px;
}

.cure-main-container .ant-layout-header {
  height: 96px;
  padding: 30px;
  padding-top: 12px;
  margin: auto;
  width: 100%;
  max-width: 1120px;
}

.cure-main-container .secondary-nav {
  background: #fff;
  z-index: 1;
  width: 100%;
  padding: 0px 12px 0px 12px;
  font-family: GlacialIndifferenceBold;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid rgb(55, 105, 152);
}

.cure-main-container header .title {
  position: absolute;
  left: 41%;
  font-family: GlacialIndifferenceBold;
  text-align: center;
}

.cure-main-container header .title h2 {
  min-width: 340px;
}

.cure-main-container header .mobile-title {
  position: absolute;
  left: 38%;
  font-family: GlacialIndifferenceBold;
  text-align: center;
}

.cure-main-container .title h2,
.cure-main-container .title h5,
.cure-main-container .mobile-title h2,
.cure-main-container .mobile-title h5 {
  margin: 0px !important;
  color: #fff !important;
}

.cure-main-container .secondary-nav a,
.cure-main-container .secondary-nav .phone,
.cure-main-container .secondary-nav .credit-card {
  color: rgb(55, 105, 152) !important;
}

.transactions-list .ant-collapse-content {
  /* background-color: #f0f2f5; */
}
.transactions .ant-card {
  background: #fff;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 22px;
}
.alert-message {
  /* font-size: 14px; */
  display: block;
  margin: 0;
}

.alert-message .image-icon {
  float: left;
  color: #fff;
}

.alert-message .title {
  color: #fff;
  width: 758px;
  line-height: 22px;
  padding-top: 9px;
}

.alert-message .anticon-info-circle {
  margin-top: 15px;
  font-size: 32px;
  margin-right: 15px;
}

.feedback {
  position: absolute !important;
  right: 32px;
  top: 15px;
  color: #294c7c;
}

.feedback span {
  color: #294c7c;
}

.phone {
  margin-right: 0px;
  transform: rotate(120deg);
}

.rsa-mobile-text {
  color: rgb(55, 105, 152) !important;
  margin-left: 1px;
}

.credit-card {
  margin-right: 6px;
}

.alert-nav-img {
  width: 143px;
  right: 0rem;
  position: absolute;
  top: 3.3em;
}

.alert-title {
  display: initial;
}

.alert-nav .logo {
  width: 52px;
}

.cure-main-container .logo-holder {
  /* margin-top: 24px; */
}

.menu-nav-mobile img,
.menu-nav img {
  filter: brightness(0) invert(1);
  width: 172px;
  display: block;
  margin-top: 9px;
  margin-bottom: 3px;
}

.menu-nav-mobile .menu-container,
.menu-nav .menu-container {
  background: transparent;
  color: #fff;
  line-height: 48px !important;
}

.menu-nav-mobile .ant-menu-horizontal,
.menu-nav .ant-menu-horizontal {
  border-bottom: none !important;
}

.menu-nav-mobile .ant-menu-title-content,
.menu-nav .ant-menu-title-content {
  text-transform: uppercase;
  font-size: 15px;
}

.menu-nav-mobile .ant-menu-title-content a,
.menu-nav .ant-menu-title-content a {
  font-family: 'GlacialIndifferenceBold';
}

.menu-nav-mobile .ant-row,
.menu-nav .ant-row {
  height: inherit !important;
}

.expanol-link-container {
  float: right;
}

.secondary-nav .center {
  text-align: center;
}

.secondary-nav .right {
  text-align: right;
  margin-top: 0px !important;
}

.menu-container li {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 64px;
}

.menu-nav-mobile {
  display: none;
}

.drawer-menu {
  background: transparent;
  color: #fff;
  border: none;
}

.ant-drawer a {
  color: #000 !important;
}

.start-quote span,
.sign-in span {
  vertical-align: middle;
  font-family: 'GlacialIndifference';
  line-height: 32px;
}

.expanol-link-container .start-quote,
.expanol-link-container .sign-in,
.expanol-link-container button {
  margin-right: 0px;
  background: #ff8000;
  border: none;
  color: #fff;
  font-family: 'GlacialIndifference';
}

.expanol-link-container a.ant-typography {
  font-size: 15px;
}

.ant-menu-inline {
  background: #fff;
  color: #000;
}
.ant-menu-submenu-title:hover {
  color: black !important;
}
.ant-drawer-body span {
  color: #000 !important;
}
.ant-menu-submenu-active div:first-child {
  background-color: #294c7c;
  color: #fff !important;
}

.ant-menu-submenu-active div:first-child span {
  background-color: #294c7c;
  color: #fff !important;
}
.ant-menu-submenu-title:after {
  display: none;
}
.ant-menu-light .ant-menu-submenu-title {
  padding-left: 12px !important;
}
.ant-menu-inline.ant-menu-root .ant-menu-item {
  padding-left: 12px !important;
}
.ant-menu-submenu-active .ant-menu-submenu-arrow {
  color: #fff !important;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  left: calc(100% - 250px);
}
.ant-drawer-body {
  font-family: GlacialInDifference;
}
li a {
  color: #000;
}

.phone-numbers {
  display: block;
}
.credit-card {
  margin-right: 6px;
}

.ant-menu-submenu-active div:first-child,
.ant-menu-submenu-active div:first-child span {
  background-color: #294c7c;
}

@media (max-width: 999px) {
  .alert-title {
    font-size: 20px !important;
  }
  .alert-nav .logo {
    width: 46px;
  }
}

@media (max-width: 880px) {
  .alert-title {
    font-size: 16px !important;
  }
  .alert-nav .logo {
    width: 52px;
  }
}

@media (max-width: 767px) {
  .secondary-nav {
    display: block;
  }
  .alert-title {
    font-size: 14px !important;
    margin: 12px !important;
  }
  .alert-nav .logo {
    width: 32px;
  }
  .cure-main-container .alert-nav {
    padding: 0 5% !important;
  }
}

@media (max-width: 590px) {
  .cure-main-container .alert-nav {
    padding: 0px !important;
    height: auto;
  }
  .secondary-nav {
    display: block;
  }
  .alert-title {
    font-size: 13px !important;
    margin: 12px !important;
  }
  .alert-nav .logo {
    width: 28px;
  }
  .alert-nav h3 {
    letter-spacing: 0px;
  }
}

@media (max-width: 1025px) {
  .menu-nav {
    display: none;
  }
  .menu-nav-mobile {
    display: block;
  }
  .secondary-nav {
    display: block;
    padding: 0 12px !important;
  }
  .ant-menu-submenu-active div:first-child,
  .ant-menu-submenu-active div:first-child span {
    background-color: #294c7c;
  }
  .expanol-link-container .menu-icon,
  .expanol-link-container .menu-icon:hover {
    margin-right: 0;
    background-color: transparent !important;
    border-color: transparent !important;
    color: #fff !important;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .secondary-nav .right {
    margin-left: 0px !important;
  }
}

@media (max-width: 577px) {
  .space-column {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .feedback {
    display: none !important;
  }
}

@media (max-width: 420px) {
  .secondary-nav a {
    font-size: 13px;
  }
  .secondary-nav .right {
    margin-left: 0px;
  }
  .feedback {
    display: none !important;
  }
}

@media (max-width: 430px) {
  .alert-message .title {
    width: 100%;
  }
  .alert-message {
    margin: 12px;
  }
  .alert-message .image-icon {
    margin-top: 42px;
    margin-bottom: 20px;
  }
}

@media (max-width: 360px) {
  .alert-title {
    font-size: 12.5px !important;
  }
}
