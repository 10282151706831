.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  min-height: 55vh;
  background-color: white;
}

@font-face {
  font-family: 'GlacialIndifference';
  src: url('./assets/fonts/GlacialIndifference-Regular.otf');
}

@font-face {
  font-family: 'GlacialIndifferenceBold';
  src: url('./assets/fonts/GlacialIndifference-Bold.otf');
}

h2 {
  margin: 24px !important;
  margin-bottom: 12px !important;
  color: #1d4c80 !important;
  font-size: 45px !important;
  font-weight: lighter !important;
  font-family: GlacialIndifferenceBold;
}

h3 {
  margin: 18px !important;
  margin-bottom: 12px !important;
  color: #fff !important;
  font-size: 1.5em;
  letter-spacing: 0em;
  font-weight: lighter !important;
  font-family: GlacialIndifference;
}

h5 {
  color: rgb(42, 90, 155) !important;
  font-size: 23px !important;
  font-weight: bolder !important;
  font-family: 'GlacialIndifference';
  text-transform: uppercase;
  margin-bottom: 12px;
}

span.sub-title {
  font-weight: 400;
  font-style: normal;
  color: rgb(45, 44, 33);
  line-height: 1.5;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  text-decoration: none;
  font-family: GlacialIndifference;
  font-size: 20px;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  text-align: center;
  display: block;
}

.ant-modal {
  font-family: 'GlacialIndifference';
}

.ant-modal-title {
  font-family: 'GlacialIndifference';
  font-weight: 400 !important;
  font-style: normal;
  color: rgb(45, 44, 33) !important;
  font-size: 18px !important;
  color: #1d4c80 !important;
  font-weight: bolder !important;
  text-transform: uppercase;
}

.ant-modal .input-text,
.ant-modal textarea {
  border: 1px solid #376998 !important;
  color: rgba(0, 0, 0, 0.85);
  font-size: 19px !important;
}

.ant-modal .ant-checkbox-wrapper {
  color: rgba(0, 0, 0, 0.85);
  font-size: 19px !important;
}

.ant-modal-footer button {
  text-transform: uppercase;
}

.ant-modal-footer .ant-btn[disabled],
.ant-modal-footer .ant-btn[disabled]:active,
.ant-modal-footer .ant-btn[disabled]:focus,
.ant-modal-footer .ant-btn[disabled]:hover {
  background: #ff8000;
  border-color: #d9d9d9;
  box-shadow: none;
  color: hsla(0, 0%, 100%, 0.5);
  opacity: 0.5;
  text-shadow: none;
}

.ant-modal-footer .ant-btn-primary,
.ant-modal-footer .ant-btn-primary:hover,
.ant-modal-footer .ant-btn-primary:active,
.ant-modal-footer .ant-btn-primary:focus {
  background: rgb(255, 128, 0);
  border: none;
  color: #fff !important;
}

.ant-modal-body .success-msg {
  color: #294c7c !important;
  font-size: 16px;
}

.ant-modal-body .error-msg {
  color: #ff4d4f !important;
}

.ant-modal-footer .ant-btn,
.ant-modal-footer .ant-btn:hover {
  color: #2a5a9b;
}

span.sub-header-title {
  font-weight: 400;
  font-style: normal;
  color: rgb(45, 44, 33);
  line-height: 2;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  text-decoration: none;
  font-family: GlacialIndifference;
  font-size: 24px;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  text-align: center;
  display: block;
}

header a span {
  color: #fff !important;
}

header a {
  color: #fff !important;
}

a {
  color: rgb(29, 76, 128) !important;
}

.ant-select-arrow {
  color: rgba(0, 0, 0, 0.85);
}

.ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.85);
}

.container .page-text-margin {
  margin-top: 12px !important;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-checked:after {
  border-color: rgb(42, 90, 155) !important;
}

.ant-radio-inner:after {
  background-color: rgb(42, 90, 155) !important;
}

.ant-modal .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1d4c80 !important;
  border-color: #1d4c80 !important;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: 12px !important;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  background-color: rgba(255, 128, 0, 1) !important;
  color: rgb(255, 255, 255) !important;
  border-color: transparent;
}

.ant-input-search-button svg {
  transform: rotate(90deg);
}

@media (max-width: 1200px) {
  h2 {
    font-size: 45px !important;
  }
  span.sub-title {
    font-size: 20px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 42px !important;
  }
  span.sub-title {
    font-size: 20px !important;
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 27px !important;
    height: 38px !important;
  }
  span.sub-title {
    font-size: 16px !important;
  }
}

@media (max-width: 480px) {
  .container {
    min-height: auto;
  }
}
@media (max-width: 376px) {
  h2 {
    font-size: 27px !important;
    height: 38px;
  }
}
