.policy-structure {
  padding-top: 40px;
  width: 100%;
}
.policy-structure p {
  margin-bottom: 0px;
  font-weight: bold;
}
.policy-structure .ant-select-single {
  margin-top: 8px;
  float: left;
}
.policy-structure .ant-space-vertical {
  width: 100%;
}

.policy-structure .ant-collapse-header-text {
  font-size: 18px;
  font-weight: 600;
}