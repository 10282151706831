footer {
  margin: auto;
  background: rgb(55, 105, 152);
  z-index: 1;
  width: 100%;
  padding: 0px 12px;
  font-family: GlacialIndifferenceBold;
  height: 96px;
  max-width: 1120px;
}

footer span {
  font-family: GlacialIndifference !important;
}

.cure-main-container footer .footer-title {
  font-family: GlacialIndifferenceBold;
  text-align: center;
  color: white;
}
